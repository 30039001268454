/*--------------------------------

Encharge Person Fields Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: "Encharge Person Fields";
  src: url("../font/person-field-icons/Encharge Person Fields.eot");
  src: url("../font/person-field-icons/Encharge Person Fields.eot")
      format("embedded-opentype"),
    url("../font/person-field-icons/Encharge Person Fields.woff2")
      format("woff2"),
    url("../font/person-field-icons/Encharge Person Fields.woff") format("woff"),
    url("../font/person-field-icons/Encharge Person Fields.ttf")
      format("truetype"),
    url("../font/person-field-icons/Encharge Person Fields.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icn-fields {
  display: inline-block;
  font: normal normal normal 1em/1 "Encharge Person Fields";
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  margin-right: 8px;
  vertical-align: -1px;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icn-fields.icn-sm {
  font-size: 0.8em;
}
.icn-fields.icn-lg {
  font-size: 1.2em;
}
/* absolute units */
.icn-fields.icn-16 {
  font-size: 16px;
}
.icn-fields.icn-32 {
  font-size: 32px;
}

/*------------------------
	icons
-------------------------*/

.icn-fields.icn-at-sign::before {
  content: "\ea02";
}

.icn-fields.icn-single-02::before {
  content: "\ea03";
}

.icn-fields.icn-calendar::before {
  content: "\ea04";
}

.icn-fields.icn-office::before {
  content: "\ea05";
}

.icn-fields.icn-phone::before {
  content: "\ea07";
}

.icn-fields.icn-house::before {
  content: "\ea09";
}

.icn-fields.icn-pin-3::before {
  content: "\ea0b";
}

.icn-fields.icn-clock::before {
  content: "\ea0c";
}

.icn-fields.icn-file-paragraph::before {
  content: "\ea0e";
}

.icn-fields.icn-text::before {
  content: "\ea0f";
}

.icn-fields.icn-tag::before {
  content: "\ea10";
}

.icn-fields.icn-pc-monitor::before {
  content: "\ea11";
}

.icn-fields.icn-wireframe::before {
  content: "\ea12";
}

.icn-fields.icn-mirror-tablet-phone::before {
  content: "\ea13";
}

.icn-fields.icn-network-connection::before {
  content: "\ea14";
}

.icn-fields.icn-url::before {
  content: "\ea15";
}

.icn-fields.icn-pen-2::before {
  content: "\ea16";
}

.icn-fields.icn-world-2::before {
  content: "\ea17";
}

.icn-fields.icn-mail::before {
  content: "\ea18";
}

.icn-star::before {
  content: "\ea19";
}
